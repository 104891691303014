<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import type { BadgeFragment, SearchResultBadgeFragment } from '#graphql-operations'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps<{
  class?: HTMLAttributes['class']
  badges?: (BadgeFragment | SearchResultBadgeFragment)[] | null
  discountPercentage?: number
  campaignDiscountPercentage?: number
  inCart?: boolean
}>()

const { t } = useI18n()
</script>

<template>
  <div
    data-testid="badgesContainer"
    :class="cn('absolute bottom-5 top-auto wfull flex flex-col translate-z-0 items-start p0', props.class)"
  >
    <template v-if="badges?.length">
      <div
        v-for="(badge, index) in badges" :key="index"
        data-testid="badge-GENERIC"
        class="mr1.25 mt1 h5 max-w-full w-fit inline-flex items-center gap1.25 ws-nowrap rd-r-1 bg-card py0.5 pl4 pr2 text-xs text-primary fw600 last-of-type:mr0"
      >
        <span class="box-border block of-hidden text-ellipsis ws-nowrap leading-[1.3]">
          {{ badge.text }}
        </span>
      </div>
    </template>

    <div
      v-if="inCart"
      data-testid="badge-IN_CART"
      class="mr1.25 mt1 h5 max-w-full w-fit inline-flex items-center gap1.25 ws-nowrap rd-r-1 bg-emerald py0.5 pl4 pr2 text-xs text-primary fw600 last-of-type:mr0"
    >
      <span class="box-border block of-hidden text-ellipsis ws-nowrap leading-[1.3]">
        {{ t('product.in_cart') }}
      </span>
    </div>

    <div
      v-if="campaignDiscountPercentage"
      data-testid="badge-CAMPAIGN_SALE"
      class="mr1.25 mt1 h5 w-fit inline-flex items-center gap1.25 ws-nowrap rd-r bg-violet-500 py0.5 pl4 pr2 text-xs text-white fw600 last-of-type:mr0"
    >
      <span class="box-border block of-hidden text-ellipsis ws-nowrap leading-[1.3]">
        -30 % EXTRA
      </span>
    </div>

    <div
      v-if="discountPercentage"
      data-testid="badge-SALE"
      class="mr1.25 mt1 h5 max-w-full w-fit inline-flex items-center gap1.25 ws-nowrap rd-r-1 bg-red-500 py0.5 pl4 pr2 text-xs text-white fw600 last-of-type:mr0"
    >
      <span class="box-border block of-hidden text-ellipsis ws-nowrap leading-[1.3]">
        -{{ discountPercentage }} %
      </span>
    </div>
  </div>
</template>
