<script setup lang="ts">

</script>

<template>
  <div class="relative wfull flex flex-col">
    <UiSkeleton class="relative h-0 max-w-full w-full items-start pt-[133.333%]" />
    <div class="h35 px3.75 pb6.25 pt3.75">
      <div class="mb1.25 h-4.5 w-full">
        <UiSkeleton class="relative mb-4 h-4.5 max-w-full w-3/4" />
      </div>
      <UiSkeleton class="relative mb-4 h-4.5 max-w-full w-5/12" />
      <!--      <div class="mt20 rd-1"> -->
      <!--        <UiSkeleton class="relative h-12.5 max-w-full w-full" /> -->
      <!--      </div> -->
    </div>
  </div>
</template>
