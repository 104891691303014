import type { SearchResultFragment } from '#graphql-operations'

export function makeOldPrice(product?: SearchResultFragment) {
  const priceBeforeDiscount = product?.customProductMappings?.priceBeforeDiscount

  if (!product || typeof priceBeforeDiscount !== 'number')
    return undefined

  // if (isProductVariant(product)) {
  //   return priceBeforeDiscount
  // }
  // else {
  if (!product.priceRange)
    return undefined

  if ('value' in product.priceRange) {
    return { value: priceBeforeDiscount }
  }
  else if ('min' in product.priceRange && 'max' in product.priceRange) {
    const maxPrice = product.priceRange.min !== product.priceRange.max
      ? Math.round(product.priceRange.max * (priceBeforeDiscount / product.priceRange.min))
      : priceBeforeDiscount

    return { min: priceBeforeDiscount, max: maxPrice }
  }
  // }

  return undefined
}
