<script setup lang="ts">
import type { SearchResultFragment } from '#graphql-operations'
import { getDiscountPercentage } from '~/utils/discount'

const props = withDefaults(defineProps<{
  item: SearchResultFragment
  interactiveHoverMode?: boolean
  priceAlignment?: 'horizontal' | 'vertical'
  cssContainmentWillBeEnabled?: boolean
  cssContainmentEnabled?: boolean
  alwaysVisibleCta?: boolean
  streamPadding?: number
  galleryMode?: 'hover'
}>(), {
  interactiveHoverMode: true,
  priceAlignment: 'horizontal',
  cssContainmentWillBeEnabled: true,
  cssContainmentEnabled: true,
  streamPadding: 0,
  galleryMode: 'hover',
})

const emit = defineEmits<{
  (e: 'click'): void
}>()

const soldOut = computed(() => false)

const interactiveMode = computed(() => soldOut.value ? false : props.interactiveHoverMode)
const interactiveHover = computed(() => soldOut.value ? false : props.interactiveHoverMode)
const secondaryPriceLabel = computed(() => undefined)
const lowestPriorPriceHint = computed(() => {
  // return 'Nejvýhodnější cena za posledních 30 dní**: 657 Kč (+42 %)'
  return undefined
})

const { activeOrder } = useActiveOrder()

const { t } = useI18n()

const priceFormatter = usePriceFormatter()

const productTileRef = ref<HTMLElement>()
// const { isOutside: mouseLeaving } = useMouseInElement(productTileRef)

const mouseLeaving = ref(true)

const inCart = computed(() => {
  if (!activeOrder.value)
    return false

  return activeOrder.value.lines.some(line => line.productVariant.product.id === props.item.id)
})

const to = computed(() => {
  // const query = (props?.item && isProductVariant(props.item)) ? `${VARIANT_ID_QUERY_PARAM}=${props.item.id}` : ''
  const path = props.item.slug ? `/products/${props.item.id}/${props.item.slug}` : `/products/${props.item.id}`
  // return query ? `${path}?${query}` : path
  return path
})

const hasGallery = computed(() => false)

const discountPercentage = computed(() => getDiscountPercentage(props.item))

const currentOldPrice = computed(() => {
  const oldPrice = makeOldPrice(props.item)
  if (!oldPrice || !props.item)
    return undefined

  if (typeof oldPrice === 'number')
    return oldPrice

  const oldPriceValue = 'value' in oldPrice ? oldPrice.value : oldPrice.min
  const priceValue = props.item.priceRange ? ('value' in props.item.priceRange ? props.item.priceRange.value : props.item.priceRange.min) : undefined

  if (!oldPriceValue || !priceValue || oldPriceValue <= priceValue)
    return undefined

  return oldPriceValue
})

const currentPrice = computed(() => {
  if (!props.item)
    return undefined
  return props.item.priceRange ? ('value' in props.item.priceRange ? props.item.priceRange.value : props.item.priceRange.min) : undefined
})

const colors = ['#663300', '#bdb76b', '#000000', ['#ffd4cf', '#ffffff']]
function hexToRGB(hexValue: string) {
  const hex = hexValue.startsWith('#') ? hexValue.slice(1) : hexValue
  const value = Number.parseInt(hex, 16)
  return `rgb(${(value >> 16) & 255}, ${(value >> 8) & 255}, ${value & 255})`
}

function hexToGradient(hexValues: string[]) {
  const hex1 = hexValues[0].startsWith('#') ? hexValues[0].slice(1) : hexValues[0]
  const hex2 = hexValues[1].startsWith('#') ? hexValues[1].slice(1) : hexValues[1]
  const value1 = Number.parseInt(hex1, 16)
  const value2 = Number.parseInt(hex2, 16)
  return `linear-gradient(90deg, rgb(${(value1 >> 16) & 255}, ${(value1 >> 8) & 255}, ${value1 & 255}) 50%, rgb(${(value2 >> 16) & 255}, ${(value2 >> 8) & 255}, ${value2 & 255}) 50%)`
}

const tileHeight = computed(() => {
  const Xe = 16
  const Et = 19
  const Bt = 12

  const p = interactiveMode.value ? Xe * 2 : Et + Bt
  const m = props.item.name ? 18 : 0
  const h = (props.item as any).brandName ? 18 : 0
  const x = !!discountPercentage.value // count every discount price
  const y = x ? 38 : 20
  // const C = item.formattedTotalReductionPrice ? 22.391 : 0
  const C = 0
  const w = 20
  const v = props.priceAlignment === 'horizontal' && !secondaryPriceLabel.value && !x ? w : y + C
  const k = colors && !interactiveMode.value && colors.length ? 18 : 0
  const I = typeof lowestPriorPriceHint.value === 'string' ? 34 : 0
  const T = interactiveMode.value ? 65.09 : 0
  const S = props.alwaysVisibleCta ? 65.09 : 0
  const A = colors && colors.length ? 32 : 0
  const z = 18
  const D = typeof lowestPriorPriceHint.value === 'string' ? 24 : 8

  const visibleContentHeight = p + m + h + k + I + S + v
  const interactiveModeContentHeight = m + h + w + A + z + D + T

  return {
    visibleContentHeight,
    interactiveModeContentHeight,
  }
})
</script>

<template>
  <li
    :data-testid="`productTileTracker-${item.id}`"
    class="list-none"
  >
    <Wrapper
      v-bind="soldOut ? undefined : {
        'as': 'div',
        'data-interactive-hover': soldOut ? undefined : interactiveHover || undefined,
        'data-css-containment-will-be-enabled': soldOut ? undefined : cssContainmentWillBeEnabled || undefined,
        'data-css-containment-enabled': soldOut ? undefined : cssContainmentEnabled || undefined,
        'class': ['relative',
                  interactiveHover && (cssContainmentEnabled || cssContainmentWillBeEnabled) ? 'h-[calc(var(--product-tile-contents-height)+(((var(--grid-width)-var(--product-tile-stream-side-padding,0)-var(--grid-gap,0)*(var(--grid-columns)-1))/(var(--grid-columns)))*(4/3)))]' : 'h-full',
        ],
        'style': {
          '--product-tile-stream-side-padding': `${streamPadding}px`,
          '--product-tile-contents-height': `${tileHeight.visibleContentHeight}px`,
        },
      }"
    >
      <NuxtLinkLocale
        ref="productTileRef"
        :data-testid="`productTile-${item.id}`"
        v-bind="{
          'data-css-containment-will-be-enabled': soldOut ? undefined : cssContainmentWillBeEnabled || undefined,
          'data-css-containment-enabled': soldOut ? undefined : cssContainmentEnabled || undefined,
          'data-interactive-hover': soldOut ? undefined : interactiveHover || undefined,
          'data-mouse-entering': soldOut ? undefined : !mouseLeaving,
          'data-mouse-leaving': soldOut ? undefined : mouseLeaving,
          'data-product-tile-gallery-mode-hover': soldOut ? undefined : hasGallery && galleryMode === 'hover',
        }"
        class="group/tile relative z0 hfull flex flex-col cursor-pointer of-hidden rd-1 bg-card plain-link"
        :class="{
          '!pointer-fine:absolute !pointer-fine:h-[calc(var(--product-tile-contents-height)+(((var(--grid-width)-var(--product-tile-stream-side-padding,0)-var(--grid-gap,0)*(var(--grid-columns)-1))/(var(--grid-columns)))*(4/3)))]': interactiveHover && mouseLeaving && (cssContainmentWillBeEnabled || cssContainmentEnabled),
          '!pointer-fine:absolute !pointer-fine:h-[calc((var(--product-tile-contents-height)+(((var(--grid-width)-var(--product-tile-stream-side-padding,0)-var(--grid-gap,0)*(var(--grid-columns)-1))/(var(--grid-columns)))*(4/3)))+var(--detail-box-height)-var(--product-tile-contents-height)-45px)]': interactiveHover && !mouseLeaving && (cssContainmentWillBeEnabled || cssContainmentEnabled),
          'rd-2 p2 box-border outline-transparent outline-solid outline-px -outline-offset-px': interactiveHover,
          'pointer-fine:z0 pointer-fine:outline-transparent pointer-fine:wfull pointer-fine:hfull pointer-fine:transition-[height,outline-color,z-index] pointer-fine:duration-[300ms,300ms,350ms] pointer-fine:ease-in-out': interactiveHover && mouseLeaving,
          'pointer-fine:wfull pointer-fine:z1 pointer-fine:outline-slate-200 pointer-fine:hfull pointer-fine:transition-[height,outline-color,z-index] pointer-fine:duration-[300ms,300ms,0s] pointer-fine:ease-in-out': interactiveHover && !mouseLeaving,
          'min-h-[calc(var(--product-tile-contents-height)+(((var(--grid-width)-var(--product-tile-stream-side-padding,0)-var(--grid-gap,0)*(var(--grid-columns)-1))/(var(--grid-columns)))*(4/3)))] contain-[size_layout_style_paint] content-visibility-auto will-change-auto intrinsic-size-[0_calc(var(--product-tile-contents-height)+(((var(--grid-width)-var(--product-tile-stream-side-padding,0)-var(--grid-gap,0)*(var(--grid-columns)-1))/(var(--grid-columns)))*(4/3)))]': cssContainmentEnabled,
        }"
        :to="to"
        :style="{
          '--detail-box-height': soldOut ? undefined : `${tileHeight.interactiveModeContentHeight}px`,
        }"
        @mouseenter="mouseLeaving = false"
        @mouseleave="mouseLeaving = true"
        @click="emit('click')"
      >
        <div v-if="hasGallery && !soldOut">
          <div class="relative">
            <ProductTileImageSlider :assets="[item.featuredAsset, item.featuredAsset, item.featuredAsset, item.featuredAsset]" scroll :gallery-mode="galleryMode">
              <div
                class="transition-transform,opacity duration-250 ease-in-out"
                :class="{ 'pointer-fine:op0 pointer-fine:translate-y-1.25': interactiveHover && !mouseLeaving }"
              >
                <div class="pointer-events-none absolute bottom-4.5 wfull flex flex-col">
                  <ProductTileBadges
                    class="relative bottom-unset top-unset m-[unset]"
                    :badges="item.badges"
                    :discount-percentage="discountPercentage"
                    :in-cart="inCart"
                  />
                </div>
              </div>
            </ProductTileImageSlider>
          </div>
        </div>

        <Wrapper
          v-bind="soldOut ? {
            as: 'div',
            class: 'relative block of-hidden',
          } : undefined"
        >
          <div
            v-if="soldOut || !hasGallery"
            data-testid="productImage"
            class="relative h0 wfull of-hidden rd-1 bg-slate-100 pt-[calc(133.333%)]"
          >
            <NuxtImg
              height="100%"
              width="100%"
              decoding="async"
              loading="lazy"
              sizes="25vw"
              :src="item.featuredAsset?.preview"
              :alt="item.name"
              data-testid="productImageView"
              class="absolute inset-0 m-auto aspect-3/4 hfull max-h-full max-w-full wfull rd-0.5 object-cover object-cc align-bottom"
            />
            <div class="pointer-events-none absolute bottom-4.5 wfull flex flex-col">
              <ProductTileBadges
                class="relative bottom-unset top-unset m-[unset]"
                :badges="item.badges"
                :discount-percentage="discountPercentage"
                :in-cart="inCart"
              />
            </div>
          </div>

          <p
            v-if="soldOut"
            data-testid="soldOutProductText"
            class="absolute inset-0 m0 flex items-center justify-center bg-background/50 p-[25%] text-center text-xs text-primary fw600"
          >
            {{ t('product.sold_out') }}
          </p>

          <div
            data-testid="productTileHeaderCTA"
            :data-interactive-hover="interactiveHover"
            class="absolute left-0 top-0 z1 wfull [&[data-interactive-hover=true]]:box-border"
          >
            <div class="flex flex-row-reverse justify-between p3.75">
              <slot name="header" />
            </div>
          </div>
        </Wrapper>

        <Wrapper
          v-bind="soldOut ? undefined : {
            as: 'div',
            class: ['flex flex-1 transition-transform duration-250 delay-100 ease-in-out pointer-fine:group-hover/tile:-translate-y-15', {
              'duration-300': interactiveHover,
              '-mb15': interactiveHover && !cssContainmentWillBeEnabled && !cssContainmentEnabled,
            },
            ],
          }"
        >
          <div
            class="relative box-border wfull flex flex-1 flex-col bg-card text-primary leading-[1.4em] transition-background-color,backdrop-filter duration-250 ease-in-out"
            :class="[interactiveMode ? 'p4' : 'pt4 pb4.75 px4', {
              'pointer-fine:bg-background/70 pointer-fine:backdrop-blur-[10px]': interactiveHover && !mouseLeaving,
            }]"
            v-bind="{
              'data-interactive-mode': soldOut ? undefined : interactiveMode || undefined,
            }"
          >
            <p
              data-testid="productName"
              class="m0 of-hidden text-ellipsis ws-nowrap p0 text-xs text-primary fw600 leading-4.5 tracking-[0.4px] uppercase md:text-left"
            >
              {{ item.name }}
            </p>

            <div
              v-if="!soldOut"
              data-testid="priceBox"
              class="mt0.5 flex flex-row flex-wrap items-center text-xs leading-4.5"
            >
              <span
                data-testid="currentPrice"
                class="text-xs fw600 leading-6 not-last:mr-[0.5em]"
                :class="{ 'text-red-500': currentOldPrice }"
              >
                {{ priceFormatter(currentPrice, item.currency) }}
              </span>

              <span
                v-if="currentOldPrice"
                data-testid="struckPrice0"
                class="inline-block shrink-0 text-slate-400 fw600 line-through not-last:mr-[0.5em]"
              >
                {{ priceFormatter(currentOldPrice, item.currency) }}
              </span>
            </div>

            <div
              v-if="!soldOut"
              class="relative z2 mt2.5"
              :class="[interactiveMode ? 'h8.75' : 'h3.75', {
                'pointer-fine:op100': interactiveHover && !mouseLeaving,
              }]"
              v-bind="{
                'data-interactive-hover': interactiveHover || undefined,
              }"
            >
              <ul
                data-testid="colorContainer"
                class="m0 flex flex-row list-none items-center gap-1.25 p0 transition-transform,opacity duration-300 ease-in pointer-fine:group-hover/tile:op0 pointer-fine:group-hover/tile:-translate-y-1.25"
                :class="{
                  '!pointer-fine:op100 !pointer-fine:translate-y-0': interactiveHover && !mouseLeaving,
                  'op0': interactiveHover,
                }"
              >
                <div
                  v-for="(color, index) in colors" :key="index"
                  :data-active="index === 0"
                  class="leading-0"
                >
                  <li
                    v-if="Array.isArray(color)"
                    :data-testid="`colorBubble-gradient-${color.join(',')}`"
                    class="relative box-content inline-block h3.5 w3.5 cursor-pointer border-4 border-slate-200 rd-full bg-no-repeat before:absolute before:rd-full before:content-[''] before:-inset-1"
                    :class="{
                      'before:border before:border-solid before:border-primary before:animate-[auto_ease_0s_1_normal_none_running_none]': index === 0,
                    }"
                    :style="{ backgroundImage: hexToGradient(color) }"
                  />
                  <li
                    v-else
                    :data-testid="`colorBubble-simple-${color}`"
                    class="relative box-content inline-block h3.5 w3.5 cursor-pointer border-4 border-slate-200 rd-full border-solid bg-no-repeat before:absolute before:rd-full before:content-[''] before:-inset-1"
                    :class="{
                      'before:border before:border-solid before:border-primary before:animate-[auto_ease_0s_1_normal_none_running_none]': index === 0,
                    }"
                    :style="{ backgroundColor: hexToRGB(color) }"
                  />
                </div>
                <li class="text-xs text-slate-400 leading-3">
                  +6
                </li>
              </ul>

              <span
                data-testid="sizesContainer"
                class="wfull translate-y-3.75 of-hidden text-ellipsis ws-nowrap text-xs text-slate-400 fw500 leading-[1.4em] op0 transition-transform,opacity duration-300 ease-in pointer-fine:group-hover/tile:translate-y-0 pointer-fine:group-hover/tile:op100"
                :class="interactiveHover ? 'relative block top-auto' : 'absolute inline -top-1.25'"
              >
                {{ t('product.available_in_many_sizes') }}
              </span>
            </div>

            <div class="relative">
              <div
                v-if="!soldOut && lowestPriorPriceHint"
                data-testid="lowestProductPrice30D"
                class="z1 mt2 box-border h6.5 flex text-2.25 text-2.5 text-slate-400 leading-3.5 transition-opacity duration-300 ease-in"
                :class="{
                  'pointer-fine:op0': interactiveHover && !mouseLeaving,
                  'absolute bottom-2': interactiveHover,
                }"
              >
                {{ lowestPriorPriceHint }}
              </div>
            </div>

            <div v-if="soldOut" class="mt-auto">
              <div class="absolute bottom-25 left-5 right-5 flex flex-col flex-nowrap gap3">
                <UiButton
                  data-testid="soldOutRemindMeButton"
                  :aria-label="t('restock_reminder.remind.label')"
                  variant="outline"
                  class="h-auto w-full flex whitespace-nowrap px-10 py-2"
                >
                  {{ t('restock_reminder.remind.label') }}
                </UiButton>
                <div>
                  <UiButton
                    data-testid="similarProductsButtonSoldOut"
                    :aria-label="t('product.similar_products')"
                    variant="outline"
                    class="h-auto w-full flex whitespace-nowrap px-10 py-2"
                  >
                    {{ t('product.similar_products') }}
                  </UiButton>
                </div>
              </div>
            </div>

            <div
              v-if="!soldOut"
              class="mt0 h0 op0 transition-opacity duration-250 delay-100 ease-in-out group-[[data-interactive-hover=true]]/tile:h-auto group-[[data-interactive-hover=true]]/tile:op0 pointer-fine:group-[[data-interactive-hover=true][data-mouse-entering=true]]/tile:op100 pointer-fine:group-[[data-interactive-hover=true][data-mouse-entering=true]]/tile:transition-opacity pointer-fine:group-[[data-interactive-hover=true][data-mouse-entering=true]]/tile:duration-250 pointer-fine:group-[[data-interactive-hover=true][data-mouse-entering=true]]/tile:delay-200 pointer-fine:group-[[data-interactive-hover=true][data-mouse-entering=true]]/tile:ease-in-out pointer-fine:group-hover/tile:op100 pointer-fine:group-[[data-interactive-hover=true][data-mouse-leaving=true]]/tile:transition-opacity pointer-fine:group-[[data-interactive-hover=true][data-mouse-leaving=true]]/tile:duration-200 !pointer-fine:group-[[data-interactive-hover=true][data-mouse-leaving=true]]/tile:op0"
            >
              <UiButton
                data-testid="productTileAddToCartButton"
                :aria-label="t('general.add_to_cart.label')"
                class="relative mt4.25 h-auto w-full flex of-hidden whitespace-nowrap px-10 py-4 fw800 leading-none tracking-wide transition-opacity duration-250 delay-100 ease-in-out"
              >
                <span>{{ t('general.add_to_cart.label') }}</span>
              </UiButton>
            </div>

            <div v-if="!soldOut && lowestPriorPriceHint">
              <div
                data-testid="lowestProductPrice30D"
                class="mt2 flex text-2.25 text-slate-400 leading-3.5 op0 transition-opacity duration-300 ease-in"
                :class="{ 'pointer-fine:op100': interactiveHover && !mouseLeaving }"
              >
                {{ lowestPriorPriceHint }}
              </div>
            </div>
          </div>
        </Wrapper>
      </NuxtLinkLocale>
    </Wrapper>
  </li>
</template>
