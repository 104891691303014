<script setup lang="ts">
import { useElementSize, useResizeObserver, useScroll } from '@vueuse/core'
import { computed, ref } from 'vue'
import type { AssetFragment } from '#graphql-operations'

const props = withDefaults(defineProps<{
  assets: AssetFragment[]
  scroll?: boolean
  galleryMode?: 'hover' | null
}>(), {
  galleryMode: 'hover',
})

const carouselRef = ref<HTMLElement>()
const itemWidth = ref(0)

const { x } = useScroll(carouselRef, { behavior: 'smooth' })

const { width: carouselWidth } = useElementSize(carouselRef)

useCarouselScroll(carouselRef as Ref<HTMLElement>)

useResizeObserver(carouselRef, (entries) => {
  const [entry] = entries

  itemWidth.value = entry?.target?.firstElementChild?.clientWidth || 0
})

const currentPage = computed(() => {
  if (!itemWidth.value) {
    return 0
  }

  return Math.round(x.value / itemWidth.value) + 1
})

const pages = computed(() => {
  if (!itemWidth.value) {
    return 0
  }

  return props.assets.length - Math.round(carouselWidth.value / itemWidth.value) + 1
})

const isFirst = computed(() => currentPage.value <= 1)
const isLast = computed(() => currentPage.value === pages.value)

function onClickNext() {
  x.value += itemWidth.value
}

function onClickPrev() {
  x.value -= itemWidth.value
}

function onClick(page: number) {
  x.value = (page - 1) * itemWidth.value
}
</script>

<template>
  <div
    ref="carouselRef"
    data-testid="productTileImageSlider"
    data-scroll="true"
    class="h-fit flex snap-x snap-mandatory snap-always no-scrollbar"
    :class="{ 'of-auto': scroll }"
  >
    <div
      v-for="(asset, index) in assets"
      :key="index"
      class="relative h0 wfull shrink-0 grow basis-full snap-center of-hidden rd-1 bg-slate-100 pt-[calc(133.333%)]"
      data-testid="productImage"
    >
      <NuxtImg
        height="100%"
        width="100%"
        :decoding="index === 0 ? 'async' : 'sync'"
        :importance="index === 0 ? 'high' : 'low'"
        :fetchpriority="index === 0 ? 'high' : 'low'"
        :loading="index === 0 ? 'eager' : 'lazy'"
        sizes="25vw"
        :src="asset.preview"
        alt=""
        data-testid="productImageView"
        class="absolute inset-0 m-auto aspect-3/4 hfull max-h-full max-w-full wfull rd-0.5 object-cover object-cc align-bottom"
      />
    </div>
  </div>

  <slot />

  <div
    :data-first-slide="isFirst"
    data-testid="productTileImageSliderNavigation"
    class="absolute top-0 z1 hfull wfull flex items-center justify-between"
  >
    <button
      :data-hidden="isFirst"
      class="cursor-pointer rd-r-5 bg-background/70 px1.6 py2.4 backdrop-blur-[10px] transition-transform,opacity duration-250 ease-in-out -translate-x-10"
      :class="[isFirst ? 'op0' : 'op100', {
        'group-hover/tile:translate-x-0': galleryMode === 'hover',
      }]"
      @click="onClickPrev"
    >
      <Icon name="ph:caret-left-bold" class="block h5.5 w5.5 shrink-0 grow-0 text-primary" />
    </button>

    <button
      :data-hidden="isLast"
      class="translate-x-10 cursor-pointer rd-l-5 bg-background/70 px1.6 py2.4 backdrop-blur-[10px] transition-transform,opacity duration-250 ease-in-out"
      :class="[isLast ? 'op0' : 'op100', {
        'group-hover/tile:translate-x-0': galleryMode === 'hover',
      }]"
      @click="onClickNext"
    >
      <Icon name="ph:caret-right-bold" class="block h5.5 w5.5 shrink-0 grow-0 text-primary" />
    </button>
  </div>

  <div
    v-if="pages > 1"
    data-testid="productTileImageSliderBullets"
    class="absolute inset-x-0 bottom-0 m-auto w-fit flex flex-row items-center justify-center rd-1.25 bg-slate-100/10 px1 py0.5 backdrop-blur-[6px] transition-opacity,transform duration-[300ms,250ms] delay-[60ms,0s] ease-in -translate-y-1"
    :class="{
      'op0 group-hover/tile:op100 group-hover/tile:-translate-y-16.25 group-hover/tile:flex': galleryMode === 'hover',
    }"
  >
    <div
      v-for="page in pages" :key="page"
      :data-testid="`productTileImageSliderBullets-bullet-${page}`"
      class="m0.5 h1.5 w1.5 rd-1/2"
      :class="page === currentPage ? 'bg-black' : 'bg-slate-300'"
    />
  </div>
</template>
